<template>
    <!-- <el-empty :image-size="400"><router-link :to="{name:'index'}"><el-button type="primary">返回首页</el-button></router-link></el-empty> -->
<div class="_404">
        <h2 class="m-0">
            <!-- 抱歉，页面未找到，<span>{{countDown}}</span>s后自动跳转到 -->
            <!-- <a href="javascript:;" @click="goHome">返回首页</a> -->
            
        </h2>
        <img src="../assets/images/404.png" alt="页面未找到">
        <el-button type="primary" @click="goHome">返回首页</el-button>
    </div>


</template>
<script>
export default {
name:"404",
data() {
            return {
                countDown: 5,
                timer: null
            }
        },
        methods: {
            goHome() {
                this.$router.push('/')
                // clearInterval(this.timer)
            }
        },
        created() {
            // setInterval(() => {
            //     this.countDown > 0 ? this.countDown-- : this.goHome()
            // }, 1000)
        }
}
</script>
<style scoped>
body {
	        background-color: #dceebc;
	    }
	
	    ._404 {
	        width: 30%;
	        margin: 5rem auto;
	    }
	
	    ._404 img {
	        width: 30rem;
	    }
	
	    ._404 a {
	        color: #010101;
	    }
	
	    ._404 a:hover {
	        color: skyblue;
	    }

</style>